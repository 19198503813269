<template>
   <div class="site-search">
	    <input name="siteSearch" type="text" id="siteSearch" placeholder="Zoeken" 
            ref="searchInput"
            :class="{'is-visible': isTriggered, 'is-active': searchActive}"
            v-model="keyword" 
            @click="initSearch" 
            @keyup="filterTags"
            @blur="checkValue">
		<button class="btn btn--search" @click="searchThis" title="Zoeken"></button>
        <button class="btn btn--clear" @click="clearSearch" v-if="keyword != null"></button>

        <div class="site-search__content" v-if="foundTags.length > 0" :class="{'is-result': searchResult.length > 0}">
            <transition name="slide-up" mode="out-in">
                <ul class="site-search__hint-list slide-up-element" 
                    v-if="searchResult.length == 0 && searchFinished === false" key="1">
                    <li v-for="(foundword, index) in foundTags" 
                        :key="index"
                        @click="chooseKeyword(foundword)">{{foundword}}</li>
                </ul>
                <ul class="site-search__result-list slide-up-element" v-else key="2">
                    <li v-for="(item, index) in searchResult" :key="index">
                        <a class="site-search__result-link" :href="item.url" v-html="item.title"></a>
                    </li>
                </ul>
            </transition>            
        </div>
        <div class="site-search__content is-result" v-if="searchFinished && searchResult.length == 0">
            <p v-html="labels['zoekresultaat-leeg']"></p>
        </div>
	</div>
</template>

<script>
    export default {
        data(){
            return {
                isTriggered: true,
                tags: [],
                pages: [],
                news: [],
                keyword: null,
                foundTags: [],
                searchActive: false,
                searchResult: [],
                searchFinished: false,
                labels: {}
            }
        },
        computed: {
            path() {
                return (window.location.href.match('peterholthuizen.nl/projects') ? '/projects/arcus' : '');
            } 
        },
        methods: {
            initSearch(){
                if(this.tags.length == 0) {
                    var $this = this;
                    this.axios.post(this.path + '/api/fetch/tag/')
                    .then(function(response) {
                        $this.tags = response.data.tags;
                        $this.pages = response.data.pages;
                        $this.news = response.data.news;
                        $this.labels = $this.getLabels(response.data.labels);
                    });
                } else {
                    if(this.searchFinished == true) {
                        this.clearSearch();
                    }
                }
            },
            triggerSearch(){
                this.isTriggered = !this.isTriggered;
                this.$nextTick(() => {
                    this.$refs.searchInput.focus();
                    this.initSearch();
                });
            },
            checkValue(e){
                if(e.target.value == '') {
                    this.keyword = null;
                    this.foundTags = [];
                }
            },
            filterTags(){
                if(this.keyword !== null && this.keyword.length >= 2) {
                    this.searchActive = true;
                    this.searchFinished = false;
                    var $this = this;
                    var kw = this.keyword.toLowerCase();
                    if(this.tags.length > 0) {
                        this.foundTags = this.tags.filter(function(tag){
                            return tag.match(kw);
                        });
                    }
                }
            },
            searchThis(){
                for(let i = 0; i < this.pages.length; i++){
                    if(this.containsTag(this.pages[i].tagID)) {
                        this.searchResult.push({title: this.pages[i].title, url: this.convertToURL(this.pages[i], 'page')});
                    }
                }
                for(let j = 0; j < this.news.length; j++){
                    if(this.containsTag(this.news[j].tagID)) {
                        this.searchResult.push({title: this.news[j].title, url: this.convertToURL(this.news[j], 'news')});
                    }
                }
                this.searchFinished = true;
            },
            containsTag(input) {
                var $this = this;
                return input.find(function(tag){
                    return (tag == $this.keyword);
                });
            },
            chooseKeyword(keyword) {
                this.keyword = keyword;
                this.searchThis();
            },
            convertToURL(item, type) {
                if(type == 'page') {
                    return this.path + '/'+item.name;
                }
                if(type == 'news') {
                    return this.path + '/nieuws/'+item.ID+'/'+item.url;
                }
            },
            clearSearch(){
                this.keyword = null;
                this.foundTags = [];
                this.searchResult = [];
                this.searchActive = false;
                this.searchFinished = false;
            },
            getLabels(data) {
                let output = {};
                for(let i = 0; i < data.length; i++){
                    output[data[i].name] = data[i].value;
                }
                return output;
            }
        }
    }
</script>