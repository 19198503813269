import "@babel/polyfill"
import axios from 'axios'
import VueAxios from 'vue-axios'
 
Vue.use(VueAxios, axios)

import SiteSearch from './SiteSearch.vue'

export default {
    install(Vue) {
      Vue.component("site-search", SiteSearch);
    }
};
  